.reservations-calendar {
  box-shadow: none !important;
  border: none;

  .react-datepicker {
    &__triangle {
      display: none;
    }

    &__day {
      width: 49px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      @media only screen and (max-width: 350px) {
        width: 30px;
        height: 30px;
      }

      @media only screen and (min-width: 350px) and (max-width: 1200px) {
        width: 35px;
        height: 30px;
      }

      @media (hover: hover) {
        &:hover {
          background: #eee !important;
        }
      }

      &--disabled {
        // pointer-events: none; // uncomment to make disabled days unselectable
        background-color: #f5f5f5;
        color: inherit;
      }

      &--passed {
        background-color: #f5f5f5;
        color: inherit;
      }

      &--available {
        background-color: #ddffde;
      }

      &--selected {
        outline: none;
        color: #fff !important;
        background-color: #1ab394 !important;
        border: 1px solid #1ab394 !important;
      }

      &--today {
        font-weight: 400;
        background-color: white;
        border: 1px solid #1ab394;
        color: inherit;
      }
    }

    &__month {
      margin-top: 0;
    }

    &__navigation,
    &__day-name {
      width: 49px;
      height: 30px;
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 350px) {
        width: 30px;
        height: 30px;
      }

      @media only screen and (min-width: 350px) and (max-width: 1200px) {
        width: 40px;
        height: 30px;
      }
    }
  }
}

.calendar-popper {
  position: relative !important;
  transform: none !important;
  max-width: 319px;
  margin: 0 auto;
}
