.duration-cell {
  width: 30%;
}

#price-table > tbody > tr > td {
  vertical-align: top !important;

  .form-group {
    min-width: 5.625rem;

    &.hours {
      min-width: 4.6875rem;
    }

    &.minutes {
      min-width: 5.625rem;
    }

    &.name {
      min-width: 7.5rem;
    }
  }
}

.button-add-service {
  white-space: nowrap;
  font-size: 11px !important;
}

.hidden-input-value {
  .form-control {
    color: #e9ecef !important;
    cursor: not-allowed;
  }
}
.duration-wrapper {
  gap: 0.625rem;
}
