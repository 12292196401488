.modal-tab {
  padding: 30px;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
}

.nav-tabs > a.active {
  border: none;
  border-bottom: 2px solid #1ab394 !important;
}
