@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

$table-border: 1px solid #ddd !important;

.hide-label {
  display: none;
}

.resource-label {
  background-color: #fff;
}

.fc-scrollgrid {
  border-top: $table-border;
}

.fc-col-header-cell {
  padding: 1px !important;
  border-right: $table-border;
}

.fc-timegrid-axis {
  border-right: $table-border;
}

.fc .fc-timegrid-slot {
  height: 2rem;
}

.fc-col-header-cell-cushion {
  color: #676a6c;

  &:hover {
    color: #676a6c;
    text-decoration: none;
    cursor: default;
  }
}

.fc-timegrid-event.fc-event-mirror {
  background: rgb(255, 196, 109) !important;
  border: 1px solid rgb(192, 134, 48);
}

.header-btn {
  @include media-breakpoint-down(lg) {
    visibility: hidden !important;
  }
}

.tooltip-line {
  margin-bottom: 2px;
  margin-top: 2px;
  width: 100%;
}

.tooltip {
  opacity: 1;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-bgevent-container:not(:empty) ~ .fc-business-container {
  display: none !important;
}

.fc-non-business {
  display: none !important;
}

.fc-bg-event {
  background-color: rgb(216, 216, 216) !important;
  opacity: 0.5 !important;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .fc-bg-event {
    opacity: 0.765 !important;
  }
}

.fc-scrollgrid-section-header {
  td {
    z-index: 1 !important;
  }
}
.grey-background-disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-resources-text {
  text-align: center;
  font-weight: 700;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relative {
  position: relative;
}
.fc,
th {
  overflow: hidden !important;
  word-break: break-all !important;
  white-space: pre-wrap !important;
}

.fc-timegrid-now-indicator-container {
  overflow: visible !important;
}

.fc-media-screen .fc-timegrid-event-harness {
  z-index: 3;
}

.fc .fc-scroller {
  -webkit-overflow-scrolling: initial;
}

.fc-timegrid-slot-lane {
  border-color: rgb(201, 201, 201) !important;
}

.fc .fc-timegrid-slot-minor {
  border-color: rgb(192, 192, 192) !important;
}

.fc-media-screen .fc-timegrid-col-bg,
.fc-media-screen .fc-timegrid-col-events,
.fc-media-screen .fc-timegrid-now-indicator-container {
  border-top: $table-border;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-bottom: none;
}

.fc-scrollgrid > tbody > tr > td {
  border-bottom: $table-border;
}

.pdf-calendar-button {
  max-height: 2.5rem;
}
