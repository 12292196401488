.experience-checkout-wrapper {
  width: 100%;
  background-color: #fff;
}

.experience-checkout {
  max-width: 590px;
  margin: 0 auto;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #272727;
  margin-top: 30px;

  &__title {
    font-size: 28px;
  }

  &__info-title {
    text-transform: uppercase;
    font-size: 12px;
  }

  &__service-title {
    font-weight: 700;
    font-size: 16px;
    width: 80%;
    text-align: center;

    &__service-subtitle {
      margin-left: 16px;
      font-size: 700;
    }
  }

  .title-address {
    display: flex;
    justify-items: center;
  }
}

.reservation__visiting_time--last-item {
  margin-bottom: 0.5rem;
}
