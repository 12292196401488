@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.custom-work-time-table {
  min-width: 400px;
  color: #676a6c;

  td,
  select {
    color: #676a6c;
    padding: 0.4rem !important;
  }

  .break-error,
  .row-invalid {
    background: #f2dede;

    select {
      border: 1px solid #ed5565;
    }
  }

  .time-start,
  time-end {
    min-width: 52px;
  }

  .time-start {
    position: relative;
    margin-right: 2px;

    &::after {
      position: absolute;
      top: 6px;
      right: -2px;
      content: ':';
      display: inline-block;
    }
  }
}

.custom-work-time-modal {
  .modal-body {
    @include media-breakpoint-up(md) {
      padding: 20px 30px 0;
    }
  }

  .modal-dialog {
    @include media-breakpoint-down(md) {
      max-width: 95%;
      width: 95%;
    }
  }
}
