.input {
  max-width: 300px !important;
  min-width: 200px !important;
}

.space-left {
  margin-left: 4px;
}

.search-button-mobile {
  width: 42px;
}
