@import '../../../App.scss';

.title {
  font-size: 13px !important;
  color: $label-color !important;
  font-weight: 700 !important;
  margin-bottom: 5px;
}

.checkBox-border {
  border-bottom: 1px solid #e7eaec !important;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
