.highlight {
  background-color: #f0f0f0 !important;

  &:hover {
    background-color: #f0f0f0 !important;
  }
}

.calendar-controls {
  .react-datepicker__day--in-range,
  .react-datepicker__month-selecting-range {
    background-color: #1ab394 !important;
    margin: 0;
    width: 1.8rem !important;

    &:hover {
      background-color: #1ab394 !important;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.customPickerInput {
  display: inline-block;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.customDayInWeek {
  &:hover {
    background-color: transparent;
  }
}
