@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

$table-border: 1px solid #ddd !important;

.hide-label {
  display: none;
}

.resource-label {
  background-color: #fff;
}

.fc-scrollgrid {
  border-top: $table-border;
}

.fc-col-header-cell {
  padding: 7px !important;
  border-right: $table-border;
}

.fc-timegrid-axis {
  border-right: $table-border;
}

.fc .fc-timegrid-slot {
  height: 2em;
}

.fc-col-header-cell-cushion {
  color: #676a6c;

  &:hover {
    color: #676a6c;
    text-decoration: none;
    cursor: default;
  }
}

.header-btn {
  @include media-breakpoint-down(lg) {
    visibility: hidden !important;
  }
}

.tooltip-line {
  margin-bottom: 2px;
  margin-top: 2px;
  width: 100%;
}

.tooltip {
  opacity: 1;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
}

.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-bgevent-container:not(:empty) ~ .fc-business-container {
  display: none !important;
}

.fc-non-business {
  display: none !important;
}

.fc-bg-event {
  background-color: #ccc !important;
}

.fc-timegrid-event .fc-event-main {
  overflow: hidden;
}

.fc-scrollgrid-section-header {
  td {
    .fc-scroller-harness {
      .fc-scroller {
        overflow: hidden !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.fc-col-header-cell-cushion {
  margin-top: 8px;
}

.fc-timegrid-event-harness {
  z-index: 3 !important;
}
