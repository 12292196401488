.reservationCategory {
  margin: 5px 0 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0.3rem;
}

.reservationsEmpty {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 8px;
  border-bottom: 1px solid #e7eaec;
  background-color: #f9f9f9;
}

.reservations {
  :global {
    thead {
      background-color: rgba(0, 0, 0, 0.075);
    }

    tbody {
      tr:last-child {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}
