.circle--avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
}

.square--avatar {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 150px;
  height: auto;
}
