@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;600;800&display=swap');

.experience__root {
  * {
    font-family: 'Roboto', sans-serif;
  }

  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: #333;
  background-color: #f9f9f9;
}
