.experience-checkbox-block {
  margin-top: 40px;

  &__label {
    font-weight: 500;
    padding-left: 10px;

    a {
      color: #337ab7;
    }
  }
}
