@import '../assets/styles/Global.scss';

* {
  font-family: 'Nunito';
}

.landing__container {
  max-width: 84.5rem;
  margin: 0 auto;
  padding: 0 36px;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
}
