.reservation-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #272727;
  width: 30%;
  font-size: 14px;

  @media screen and (max-width: 820px) {
    margin-top: 10px;
    width: 100%;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__payment {
    color: #1ab394;
    padding-top: 3px;
    align-self: flex-end;
  }

  .service-container {
    display: flex;
    align-items: flex-end;

    &__person-count {
      margin-left: 0.5rem;
      display: inline-block;
      font-weight: 700;
      flex-basis: 35%;
    }
  }
}
