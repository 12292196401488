@import '../../../App.scss';

.group-header {
  background-color: #f3f3f4;
  align-items: center;
  min-height: 50px;
  cursor: pointer;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  margin-bottom: 4px;
}

.service-row {
  font-weight: 600;
  font-size: 14px;
  color: 'black';
  width: 25%;
}

.group-name {
  text-transform: capitalize;
}

#group-table > tbody > tr:first-child > td {
  border: none;
}

#group-table > tbody > tr > td {
  width: 18%;
}

#group-table > tbody > tr > td:first-child {
  width: 5%;
}

#group-table > tbody > tr > td:last-child {
  width: 40%;
  border-bottom: 1px solid #e7eaec;
}

#group-table > tbody > tr:last-child > td:last-child {
  border-bottom: none;
}
