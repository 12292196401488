.mt-10px {
  margin-top: 10px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mr-10px {
  margin-right: 10px;
}

.ml-10px {
  margin-left: 10px;
}

.container {
  max-width: 82.25rem;
  margin: 0 auto;
  padding: 0 36px;
}
