.landing {
  &__hero-section {
    background-color: #f3f3f4;
    padding: 6.875rem 0 14.375rem;

    &__left-side {
      max-width: 27.9375rem;

      h2 {
        font-size: 2rem;
        color: #2e4050;
        line-height: 2.7281rem;
        font-weight: 800;
        margin: 0;
      }

      p {
        font-size: 1rem;
        line-height: 1.3638rem;
        color: #35afb4;
        margin: 2rem 0 2.6875rem;
      }
    }

    &__right-side {
      img {
        width: 100%;
      }
    }

    &__hero-wrapper {
      align-items: center;
    }

    @media (max-width: 768px) {
      &__content-wrapper {
        flex-direction: column;
        row-gap: 2.9375rem;
      }
      &__container {
        padding: 0 1rem;
      }
      padding: 3.3125rem 0 6.5625rem;
      &__left-side > p {
        margin: 1rem 0;
      }
      button {
        padding: 0.6rem 2rem;
        font-size: 1.5rem;
        line-height: 2.0463rem;
      }
      &__hero-wrapper {
        align-items: flex-start;
      }
    }
  }
}
