@import '../../../App.scss';

.title {
  font-size: 0.8125rem !important;
  color: $label-color !important;
  font-weight: 700 !important;
  margin-bottom: 0.625rem;
}

.subtitle {
  font-size: 0.8125rem !important;
  color: $label-color !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.status-active {
  color: map-get($theme-colors, primary) !important;
}

.status-sent {
  color: map-get($theme-colors, warning) !important;
}

.status-inactive {
  color: map-get($theme-colors, danger) !important;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status-wrapper-inner {
  display: flex;
  align-items: center;
  height: 1.2188rem;
}

.status-button {
  margin-top: 0.625rem !important;
}

.refresh-button {
  cursor: pointer;
  margin-left: 0.125rem;
  width: 1rem;
  height: 1rem;
}

.button-off {
  padding: 0.625rem;
  width: 9.375rem;
}
