@import '~bootstrap/scss/bootstrap.scss';

.customer-import {
  .customer-import__error {
    color: $danger;
  }

  .customer-import__rules-list {
    &--header {
      margin-bottom: 0;
    }

    list-style: decimal inside;
    padding-left: 1.5rem;

    li {
      display: list-item;
    }
  }

  .custom-file-upload {
    font-weight: 400;

    button {
      border: 1px solid grey;
      border-radius: 2px;
      margin-right: 0.3rem;
    }

    input[type='file'] {
      display: none;
    }
  }
}
