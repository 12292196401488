.landing__button {
  border: 1px solid #35afb4;
  border-radius: 1.875rem;
  background-color: #35afb4;
  padding: 0.6rem 2rem;
  color: white;
  font-family: 'Nunito';
  font-size: 1.5rem;
  font-weight: 800;
  width: fit-content;
  outline: none;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  line-height: 2.0463rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.32rem 1.5rem;
  }
  &:hover {
    color: #1ab394;
    background-color: white;
  }
}
