.admin-worktime {
  .table-responsive-md {
    overflow: auto;
    width: 100%;
  }

  .table {
    color: inherit;
    margin-bottom: 0;

    tbody tr:hover {
      background: white;
    }

    th {
      border-bottom: 1px solid #dddddd !important;
      background: #f5f5f6 !important;
    }

    .cell-disabled {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #f5f5f6;
      text-transform: uppercase;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3a3a3a;
    }

    th,
    td {
      border: 1px solid #e7e7e7 !important;
      text-align: center;
      width: 137px;
      min-width: 137px;
      padding: 8px;
      position: relative;
    }

    tr > td:nth-child(1),
    tr:nth-child(1) > th:nth-child(1) {
      background-color: #fff;
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      z-index: 1;
      @media screen and (min-width: 768px) {
        width: 217px;
        min-width: 217px;
      }
    }

    td:not(:nth-child(1)) {
      &:hover {
        cursor: pointer;
        background-color: #1ab394;
        color: #fff;
      }
    }
  }
}
