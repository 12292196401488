.add-edit-reservation {
  .modal-dialog.modal-xl {
    max-width: 95%;
  }

  .title {
    font-weight: 600 !important;
  }

  .btn-light {
    color: inherit;
  }
}
