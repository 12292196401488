@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '../assets/styles/Global.scss';

.loginRoot {
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .card {
    max-width: 460px;
    height: fit-content;

    .logo {
      width: 40%;
      cursor: pointer;
      align-self: center;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .terms-privacy-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    a {
      margin: auto;
      cursor: pointer;
      text-decoration: none;
      color: gray;
      margin-top: 0.25rem;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
