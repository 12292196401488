@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.work-time-table {
  color: #676a6c;
  min-width: 480px !important;

  td,
  select {
    color: #676a6c;
    padding: 0.4rem !important;
  }

  .break-error,
  .row-invalid {
    background: #f2dede;

    select {
      border: 1px solid #ed5565;
    }
  }

  .time-start {
    position: relative;
    margin-right: 2px;

    &::after {
      position: absolute;
      top: 6px;
      right: -2px;
      content: ':';
      display: inline-block;
    }
  }

  .form-check-label {
    padding-left: 15px;
    font-weight: 600 !important;
  }

  .gray-bg {
    background-color: #f3f3f4;
  }

  .row-disabled {
    select,
    button {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.work-time-modal {
  .modal-body {
    @include media-breakpoint-up(md) {
      padding: 20px 30px 0;
    }
  }

  &.modal-dialog {
    @include media-breakpoint-down(md) {
      max-width: 95%;
      width: 95%;
    }
  }

  .modal-tab {
    padding: 20px 0 10px;
  }

  .modal-footer {
    .btn-danger {
      display: none !important;
    }
  }
}

.work-time-table {
  th {
    &:nth-child(2),
    &:nth-child(3) {
      @include media-breakpoint-down(xl) {
        min-width: 10.5rem;
      }
      @include media-breakpoint-down(sm) {
        min-width: 8.5rem;
      }
    }
  }
}
