@mixin twoBlockLayout($mobileFlexDirection: column) {
  display: flex;
  flex-direction: row;
  grid-gap: 150px;

  @media screen and (max-width: 770px) {
    flex-direction: $mobileFlexDirection;
    grid-gap: 1rem;
  }
}

.experience__date-time-block {
  @include twoBlockLayout(column);

  .experience__block {
    width: 100%;
    @media screen and (max-width: 770px) {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experience__block-bottom {
  @include twoBlockLayout(column-reverse);
  padding-bottom: 2rem;

  .experience__container {
    width: 100%;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding: 0;

      font-size: 14px;
      font-weight: 900;
      color: #1ab394;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .experience__container_button_wrapper {
      display: grid;
      grid-gap: 10px;
    }
  }
}

.experience__person-count-btn {
  border: 2px solid #d7d7d7;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 1.25rem;
  line-height: 1;
  font-weight: bold;
  color: #333;
  height: 2rem;
  width: 2rem;
}

.experience__person-count-input {
  line-height: 1;
  height: 100%;
  border: 2px solid #d7d7d7;
  border-left: none;
  border-right: none;
  font-size: 1.1rem;
  text-align: center;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.experience__person-count-box {
  height: 2rem;
  display: flex;
  align-items: center;
}

.experience__reservertion-options {
  .experience__block {
    width: 100%;
    max-width: 20.9375rem;
  }
  .experience__reservation_edit_form {
    margin-left: auto;
  }

  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    .experience__block {
      max-width: 100%;
    }
  }
}
