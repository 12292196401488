@import '../../App.scss';

$color-border-gray: #ddd;
$color-background: #ffff;

.google-status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.75rem;
}

.status-box {
  border: 1px solid $color-border-gray;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $color-background;

  .img-wrapper {
    border-bottom: 1px solid $color-border-gray;
    width: 18.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3125rem;
  }

  p {
    font-weight: 500;
    max-width: 13.75rem;
    margin: auto;
    padding: 1.5625rem 0.3125rem 1.875rem;
    text-align: center;
  }
}
