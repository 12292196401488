.landing {
  &__header {
    background-color: #ffffff;
    padding: 1rem 0;
  }
  &__container {
    max-width: 82.25rem;
    margin: 0 auto;
    padding: 0 36px;
  }
  @media (max-width: 23.4375rem) {
    &__logo {
      max-width: 6.25rem;
    }
  }
}
