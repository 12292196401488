.experience__service-category {
  padding-top: 1rem;
  position: relative;

  &:not(:first-of-type) {
    border-top: 1px solid lightgray;
  }

  &.expanded {
    &:last-of-type {
      padding-bottom: 1rem;
    }
  }

  h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    color: #272727;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 1rem;

    background-image: url(../../assets/down-1.png);
    background-repeat: no-repeat;
    background-position: 98% 30%;

    &:hover {
      cursor: pointer;
    }
  }

  &--count {
    border-radius: 20px;
    background-color: #ddf4ef;
    text-align: center;
    margin-left: 8px;
    padding: 5px 13px;
    font-size: 12px;
    font-weight: 400;
  }

  &--items {
    display: none;
    overflow: hidden;

    .expanded & {
      display: flex;
      flex-direction: column;
      animation: animateCollapse 0.55s;
    }
  }

  @keyframes animateCollapse {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
