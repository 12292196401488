@import '../../App.scss';

.title-big {
  font-size: 24px;
  font-weight: 100;
  background-color: #ffffff !important;
}

#location-table > thead > tr {
  background-color: $background-color !important;
}
