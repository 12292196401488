@import '../../../App.scss';

.flexContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon {
  color: #f8ac59;
  width: 120px;
  height: 120px;
}

.attention {
  color: $label-color;
  font-size: 2rem;
  font-weight: 600;
}

.to-delete {
  color: $label-color;
  font-size: 1.2rem;
  font-weight: 300;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button {
  min-height: 40px;
  min-width: 90px;
}

.close-button {
  background-color: #c1c1c1 !important;
  border: none !important;
}

.close-button:hover {
  background-color: #b4b4b4 !important;
}

.agree-button {
  background-color: #dd6b55 !important;
}

.agree-button:hover {
  background-color: rgb(212, 103, 82) !important;
}

.container-body {
  padding: 30px !important;
}
.modal-backdrop {
  z-index: 1050 !important;
}
