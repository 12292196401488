// Variables
$theme-colors: (
  'primary': #1ab394,
  'warning': #f8ac59,
  'info': #23c6c8,
  'danger': #ed5565,
);

$label-color: #676a6c;
$background-color: #f3f3f4;

@import '~bootstrap/scss/bootstrap';

// Global styles
#root {
  height: 100vh;
}

body {
  background-color: $background-color !important;
  margin: 0;
  color: $label-color;
  font-size: 13px;
}

.form-check-label {
  font-weight: 400 !important;
}

//remove error icon from input
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.form-control {
  border-radius: 1px !important;

  &:focus {
    border-color: #1ab394;
    box-shadow: none;
  }
}

.opacity-50 {
  opacity: 0.5;
}
