@import '../../../App.scss';

label {
  font-size: 13px;
  font-weight: 700;
  color: $label-color;
}

.font-weight-basic {
  font-weight: 400;
}

.grey-borders {
  border: solid 0.5px rgb(231, 234, 236) !important;
}

.title {
  font-size: 13px !important;
  padding-left: 4px;
  font-weight: 400 !important;
}
