.rf-employees {
  margin: 20px 0;

  &__item {
    width: max-content;

    .form-radio {
      position: relative;
      margin-bottom: 5px;
      padding: 4px 10px;
      border: 1px solid transparent;
      display: inline-flex;
      width: auto;
      flex-wrap: nowrap;
      cursor: pointer;

      input {
        visibility: hidden;
        height: 0;
        width: 0 !important;
        margin: 0;
      }

      label {
        min-height: 32px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        color: inherit !important;
      }

      &:hover {
        border-color: #1ab394;
      }
    }

    &.random {
      .form-radio {
        &::before {
          content: '';
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          border-radius: 50%;
          background-color: #e0dfdf;
          display: inline-flex;
          color: #fff;
          background-size: contain;
          margin-right: 5px;
          align-items: center;
          justify-content: center;
          background-image: none;
          font-size: 20px;
          font-weight: 900;
          content: '?';
        }
      }
    }

    &.active {
      .form-radio {
        border-color: #1ab394;
      }
    }

    .text-danger.form-text {
      display: none;
    }
  }
}
