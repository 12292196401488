.form-radio {
  align-items: center !important;

  input {
    flex: none !important;
    height: 15px;
    width: 15px !important;
    min-height: 15px;
    min-width: 15px;
    margin-right: 10px;
  }

  .label-avatar {
    content: '';
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: inline-flex;
    color: #fff;
    background-size: contain;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;

    // default avatar
    background-image: url('../../assets/images/user-placeholder.jpg');
    background-color: #e0dfdf;
  }
}
