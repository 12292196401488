.commentsTextarea {
  min-height: 177px;
}

.root {
  input,
  select {
    font-size: 14px;
  }
}

.selectWrapper {
  :global {
    .text-danger {
      display: none !important;
    }

    .form-group {
      @media screen and (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }

  label {
    display: none;
  }

  select {
    @media screen and (min-width: 992px) {
      padding: 0 !important;
    }
  }
}
