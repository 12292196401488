@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.reports-picker {
  .react-datepicker__day,
  .react-datepicker__month-text,
  .month-selecting-range,
  .react-datepicker__year-text,
  .react-datepicker__quarter-text {
    &--selected,
    &--in-selecting-range {
      color: black;
      background-color: unset !important;
      border-color: unset !important;

      &:hover {
        color: black;
        background-color: #bbb !important;
        border-color: #bbb !important;
      }
    }

    &--in-range {
      color: black;
      background-color: #eee !important;
      border-color: #eee !important;

      &:hover {
        color: black;
        background-color: #bbb !important;
        border-color: #bbb !important;
      }
    }

    &--range-start {
      background-color: gray !important;
      border-color: gray !important;

      @media (hover: hover) {
        &:hover {
          color: #fff;
          background-color: gray !important;
          border-color: gray !important;
        }
      }
    }

    &--range-end {
      color: #fff;
      background-color: #1ab394 !important;
      border-color: #1ab394 !important;

      @media (hover: hover) {
        &:hover {
          color: #fff;
          background-color: #1ab394 !important;
          border-color: #1ab394 !important;
        }
      }
    }

    &--keyboard-selected {
      background-color: transparent;
    }
  }
}

.reports-custom-calendar {
  width: 100%;
  display: flex;
}

.reports-calendar-input {
  text-align: center;
  padding: 4px;
  font-size: 14px;
  width: 80%;
  border: 1px solid #ced4da;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #555;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid #1ab394;
  }
}

.reports-calendar-icon-wrapper {
  width: 20%;
  border: 1px solid #ced4da;
  border-right: unset;
  display: grid;
  place-items: center;
}

.reports-calendar-icon {
  font-size: 18px;
}

@include media-breakpoint-down(sm) {
  .report-buttons {
    flex-direction: column;

    &__btn {
      width: 100%;
      justify-content: center;
      margin-bottom: 8px;
    }
  }
}
