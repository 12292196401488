@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

$color-bcgr-gray: #e6e6e6;
$color-border-gray: #ddd;

.subheader-btn {
  display: none;
}

.calendar-button {
  border: 1px solid $color-border-gray !important;
  font-size: 16px !important;

  &:hover {
    background-color: $color-bcgr-gray !important;
    color: unset !important;
  }
}

.btn-active {
  background-color: $color-bcgr-gray !important;
  border: 1px solid #d2d2d2 !important;
}

.btn-arrow {
  font-size: 12px;
  padding-bottom: 2px;
}

.calendar-picker {
  text-transform: capitalize;
  border: 1px solid $color-border-gray !important;
  padding: 6px 0;
  text-align: center;
  font-size: 16px;
  color: #676a6c;

  &:hover {
    background-color: $color-bcgr-gray !important;
    color: unset !important;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.react-datepicker-wrapper {
  margin: 0;
}

.react-datepicker-popper {
  z-index: 10;
}

@include media-breakpoint-down(lg) {
  .days-control,
  .view-control {
    margin-top: 12px;
  }

  .subheader-btn {
    display: flex !important;
  }
}

@include media-breakpoint-down(sm) {
  .view-control {
    :last-child {
      margin-left: 23px !important;
    }
  }

  .subheader-btn {
    margin-bottom: 12px;
  }

  .react-datepicker-wrapper,
  .btn-wide {
    flex: 1;
    justify-content: center;
  }

  .calendar-picker {
    width: 50vw !important;
  }
}
