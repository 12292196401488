@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

$sidebarWidth: 220px;
$sidebarCollapsedWidth: 70px;

.sidebar {
  background-color: #2f4050;
  z-index: 10;
  width: $sidebarCollapsedWidth !important;
  max-width: $sidebarCollapsedWidth !important;
  overflow: visible;
  transition: all 0.65s ease;
  .userArea .logo {
    transition: all 0.65s ease;
  }

  .nav.list-group {
    background-color: #2f4050;
  }

  @keyframes hide {
    0% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(-30px);
    }
    100% {
      transform: translateX(-100px);
    }
  }

  @keyframes show {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-down(sm) {
    width: $sidebarCollapsedWidth !important;
    max-width: $sidebarCollapsedWidth !important;
    display: none;
    animation: hide 0.5s forwards ease-in-out;
    animation-delay: 0.2s;
    .userArea .logo {
      max-width: 35px !important;
    }

    .userProfile {
      display: none !important;
    }

    .nav {
      display: flex;
    }

    .link-name {
      display: none !important;
    }
    .settings-list-active {
      margin-left: -7px !important;
    }
  }

  .userArea .logo {
    // Logo on inactive dekstop sidebar
    align-self: center;
    justify-self: center;
    width: 100%;
    max-width: 65px;
    margin-left: 6px;
  }

  &.active {
    .userProfile {
      z-index: 9 !important;
      animation: userProfileOpacity 0.85s;
    }
  }

  .userProfile,
  .nav-link span {
    display: none;
  }

  &.active {
    width: $sidebarWidth !important;
    max-width: $sidebarWidth !important;

    @include media-breakpoint-down(sm) {
      width: $sidebarCollapsedWidth !important;
      max-width: $sidebarCollapsedWidth !important;
      display: block !important;
      animation: show 0.5s forwards ease-in-out !important;
      animation-delay: 0.2s;

      .settings-list-active {
        margin-left: -7px;
      }
    }

    .userProfile,
    .nav-link span {
      display: block;
    }
    .userArea .logo {
      width: 65px;
    }
    .settings-list-active {
      margin-left: 4px;
    }
  }

  .collapseSidebar {
    display: none;
    @include media-breakpoint-down(sm) {
      display: inline-block;
      color: white;
      height: 35px;
      position: absolute;
      top: 20px;
      left: calc(#{$sidebarWidth} - 30px);
    }
  }
  .nav-item {
    height: 50px;
  }

  #settings-list-settings-item {
    padding: 0 !important;
  }

  .settings-list-item-text {
    padding: 12px 20px;
  }

  .nav-link {
    height: 100%;
    color: #a7b1c2;
    background-color: #2f4050;
    &.active {
      border: none;
      background-color: #293846 !important;
      border-left: 4px solid #19aa8d;
    }

    &.active,
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }

  .sidebarButton {
    background-color: #1ab394;
    color: white;
  }

  .logoutButton {
    &.btn {
      display: flex;
      color: #999c9e;
      align-items: center;
      font-weight: 600;
    }
  }

  .userArea {
    &.card {
      background-image: url('../assets/images/user-area.png');
      border: none;
    }
  }

  .userDropdown {
    color: white !important;
    white-space: normal;
    width: 140px;
    text-align: left;
    font-size: 14px;
  }

  @keyframes userProfileOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.userMenu {
  width: 100%;
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
}

.borderless {
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.borderless-full {
  border: none !important;
}

.nav-link-nested {
  color: #a7b1c2;
  padding-left: 60px !important;
  padding-right: 10px !important;
  background-color: #2f4050 !important;

  &.active,
  &:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.01) !important;
  }

  &.active {
    background-color: #293846 !important;
  }
}

.nav-link-nested-active {
  background-color: #293846 !important;
  margin-left: 4px !important;

  &:hover {
    color: white;
    background-color: #293846 !important;
  }
}

.drawer-small {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer;
  z-index: 3 !important;
}

.overflow-visible {
  overflow: visible;
}

.hide-list {
  display: none;
}

.show-list {
  display: flex !important;
  left: 69px;
  top: 0px;
  position: absolute;
  width: 140px;

  .nav-link-nested {
    padding-left: 20px !important;

    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    &.active,
    &:hover {
      padding-left: 20px !important;
      color: white;
    }
  }

  &.active,
  &:hover {
    color: white;
    background-color: #2f4050 !important;
  }
}

.settings-list-active {
  margin-left: -7px !important;
}

.user-profile-item {
  &:first-child {
    &:hover {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
    }
  }
  &:last-child {
    &:hover {
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
}

.settings-arrow {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 3px;
}
