.experience__button {
  border-radius: 0.25rem;
  color: white;
  background-color: #1ab394;
  border: 0.125rem solid #1ab394;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: #1ab394;
    background-color: white;
  }
  &:focus {
    outline: none;
  }

  &--size-sm {
    padding: 0.125rem 1.1875rem;
    font-size: 0.875rem;
    font-weight: 700;

    @media screen and (max-width: 47.9375rem) {
      font-size: 0.75rem;
    }
  }

  &--size-lg {
    padding: 0.75rem 0.9375rem;
    font-size: 1.125rem;
  }

  &--size-100 {
    padding: 0.75rem 0.9375rem;
    font-size: 1.125rem;
    width: 100%;
    font-weight: bold;
  }

  &--size-hollow-lg {
    font-size: 1rem;
    font-weight: 700;
    padding: 0 3.375rem;
    color: #1ab394;
    background-color: white;

    @media screen and (max-width: 47.9375rem) {
      padding: 0.75rem 0.9375rem;
    }

    &:hover {
      color: white;
      background-color: #1ab394;
    }
    &:focus {
      outline: none;
    }
  }
}
