@import '../App.scss';

.terms_and_policies {
  padding: 1.5rem 0;
  color: black;
  max-width: 42.5rem;
  margin: auto;
  text-align: justify;

  h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  h2 {
    font-size: 0.875rem;
    font-weight: bold;
  }
  span {
    font-weight: bold;
  }
  p {
    font-size: 0.875rem;
  }

  ul {
    list-style: decimal;
  }

  .bigH1 {
    font-size: 1rem;
  }

  .bolded-intro {
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) {
  .terms_and_policies {
    padding: 1.25rem;
  }
}
