.experience-form-error {
  font-size: 12px;
  color: #f97f65;
  position: relative;

  &__triangle {
    color: #f97f65;
    font-size: 14px;
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
