// PageTitle
.experience {
  &__header {
    padding: 1rem 0;
    background-color: white;
    border-bottom: 1px solid lightgray;

    .experience__row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__page-title {
    font-size: 28px;
    color: #272727;
    margin: 0;
    font-weight: 700;
  }

  &__locale-select {
    border: 2px solid #dbdbdb;
    border-radius: 4px;
    width: 56px;
    height: 36px;
    padding-bottom: 2px;
    font-weight: 700;
    font-size: 14px;
    color: #555;

    &:hover {
      cursor: pointer;
    }

    &:focus,
    &:active {
      border-color: #66afe9;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }
  }
}
