.landing__current-language {
  margin: 0;
  font-weight: 800;
  color: #35afb4;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}

.arrow {
  width: 0.875rem;
  height: 0.5625rem;
  margin-left: 0.5625rem;
  @media (max-width: 768px) {
    width: 0.5rem;
    height: 0.3438rem;
  }
}

.landing__language-selector {
  margin-left: 1.875rem;
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s ease-in-out;
  min-width: 4.375rem;

  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    min-width: 2.5rem;
    margin-left: 1rem;
  }
}

.landing__language-options {
  position: absolute;
  width: 100%;
  border-radius: 0.25rem;
  padding: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  max-height: 18.75rem;
  overflow-y: auto;
  display: none;
}

.landing__language-options--open {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.landing__language-option {
  padding: 0 1rem;
  font-size: 1rem;
  margin: 0;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

  &:hover {
    background-color: #35afb4;
    color: white;
  }
}
