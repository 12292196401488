.landing__why-plus {
  background-color: white;
  padding: 4.875rem 0;

  &__title {
    font-size: 2rem;
    color: #2e4050;
    line-height: 2.7281rem;
    font-weight: 800;
  }

  &__icon {
    margin-right: 2.625rem;
    margin-top: 0.5rem;
  }

  &__reasons-wrapper {
    row-gap: 2.5625rem;
    flex-direction: column;
    margin-top: 4.8125rem;
  }

  &__reason {
    h3 {
      font-size: 2rem;
      line-height: 2.7281rem;
      color: #35afb4;
      font-weight: 800;
      margin: 0;
    }
    p {
      font-size: 1rem;
      color: #2e4050;
      line-height: 1.3638rem;
      margin: 1rem 0 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 2.5rem 0 8.6875rem;
    &__container {
      padding: 0 1rem;
    }
    &__icon {
      margin-right: 1rem;
      max-width: 1.625rem;
    }
    &__reasons-wrapper {
      row-gap: 1.5rem;
      margin-top: 2rem;
    }
    &__reason {
      h3 {
        font-size: 1.25rem;
        line-height: 1.705rem;
      }
      p {
        font-size: 0.75rem;
        line-height: 1.0231rem;
        margin-top: 0.25rem;
      }
    }
  }
}
