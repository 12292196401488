.reservation-form {
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 30px 0 60px;

  &__inputs {
    display: flex;
    flex-direction: column;
    width: 60%;

    &--two {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 820px) {
        flex-direction: column;
      }
    }

    &--inline {
      width: 60%;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }

    &--small {
      .experience-input-block__input-container {
        width: 100%;
      }

      .experience-input-block__input {
        min-width: unset;
      }
    }
  }

  &__privacy-link {
    color: #337ab7;
    &:hover {
      color: #10705d;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__privacy-modal {
    color: #333;
    padding: 1rem;
    font-size: 14px;

    h5 {
      margin-bottom: -0.5rem;
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .reservation-form {
    flex-direction: column;

    &__inputs {
      width: 100%;
    }
  }
}
