.experience-input-block.client-modal {
  flex-direction: column;

  label {
    text-transform: capitalize;
    font-weight: bold;
  }

  .experience-input-block__input-container {
    width: 100%;
  }

  .text-danger {
    font-size: 0.8125rem;
  }

  input {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    min-width: unset;
    border: 1px solid #ced4da;
    border-radius: 1px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &.experience-input-block__input--error {
      border-color: #ed5565;

      &:focus,
      &:active {
        border-color: #ed5565;
        box-shadow: 0 0 0 0.2rem rgba(237, 85, 101, 0.25) !important;
      }
    }

    &:focus,
    &:active {
      border-color: #1ab394;
      box-shadow: none;
    }
  }
}
