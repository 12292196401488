.small-event-style {
  display: flex;
  flex-direction: row;
}

#event-tooltip {
  opacity: 1;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.tooltip-comment {
  width: 200px;
}
