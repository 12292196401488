@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.reservation-info-modal {
  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  .reservation-row:not(:nth-last-child(2)) {
    border-bottom: 0.5px solid #e7eaec;
  }

  .title {
    font-weight: 600 !important;
    width: 100%;
  }

  .title,
  .desc {
    padding: 0.5em;
  }

  .client-info {
    font-weight: 400;
  }

  .btn-light {
    color: inherit;
  }

  .reservation-userinfo {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .badge-reservation {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-left: 4px;

    &.orange {
      background: #ff7f27;
      color: white;
    }
  }
  .ml-0 {
    margin-left: 0;
  }

  .client-comment {
    margin: 6px 0 0;
    padding-top: 4px;
    padding-left: 8px;
    width: 100%;

    &__text {
      font-weight: 400;
      display: inline-block;
      margin-left: 0;
    }
  }

  .word-break {
    word-break: break-all;
  }

  .status {
    color: white;
    text-align: center;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;
    font-size: 12px;
  }
  .status-paid {
    background-color: #53c6ae;
  }
  .status-unpaid {
    background-color: #faa593;
  }

  .busyness {
    color: #23c6c8;
  }

  .multi-res-trow {
    margin: 1rem 0;
  }

  .multires-users-table {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      overflow: auto;
    }

    table {
      border-collapse: separate;
      border-spacing: 0 0.25rem;

      td {
        padding: 0.5rem 0.75rem !important;
        margin: 0.2rem 0 !important;

        &:not(.last-cell) {
          border-bottom: 1px solid #dee2e6;
        }
        &.first-cell {
          border-left: 1px solid #dee2e6;
        }
        &.prelast-cell {
          border-right: 1px solid #dee2e6;
        }
        &.last-cell {
          border-top: none;
        }

        @include media-breakpoint-down(sm) {
          min-width: 7rem;
        }
      }

      th {
        font-size: 11px;
        border: none;
        white-space: normal !important;
        word-break: normal !important;
        @include media-breakpoint-down(sm) {
          white-space: nowrap !important;
        }
      }
    }
  }

  .centerized-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.break-word {
  max-width: 7rem;
  word-break: break-word;
}

.edit-icon {
  width: 0.8rem;
  overflow: visible;
}
