.accept-message {
  display: flex;
  justify-content: center;
  background-color: #ebf8fc;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;

  &__icon {
    color: #3fbee0;
    font-size: 26px;
    margin-right: 8px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
    width: 95%;

    &__icon {
      font-size: 20px;
    }
  }
}
