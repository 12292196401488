.experience__footer {
  padding-top: 1.5rem;
  background-color: white;
  border-top: 1px solid lightgray;
  flex: 1;

  &--checkout {
    border-top: unset;
  }

  .experience__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #272727;
    margin-bottom: 10px;
  }

  img {
    max-width: 120px;
  }
}
