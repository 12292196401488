@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.tabs-form {
  .tab {
    padding: 30px 0 15px;
  }

  .nav-tabs {
    margin: 0;
  }

  .nav {
    border-bottom: 1px solid #e5e6e7;
  }

  .nav-item {
    font-weight: 600;
    color: #a7b1c2;

    &.active {
      color: #555555;
    }

    &:hover,
    &:focus {
      color: #555555;
      border-radius: 0;
      background-color: #f5f5f5;
      border-color: #f5f5f5 !important;
    }
  }

  .nav-tabs > a.active {
    border: none;
    border-bottom: 2px solid #1ab394 !important;
  }

  .tabs-form-header {
    border: none !important;
    background: white !important;
    padding: 15px 20px 0 !important;

    & + .card-body {
      padding-top: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .tabs-form {
    .nav {
      flex-direction: column;
      align-items: center;
      border-bottom: none;
    }

    .nav-item {
      text-align: center;
      border: none;
      margin: 0 !important;
      padding: 10px;
      border-bottom: 1px solid #e5e6e7 !important;
      width: 100%;
    }
  }
}
