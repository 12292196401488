.DayPicker {
  border: 2px solid #d7d7d7;
  border-radius: 4px;
  background-color: white;
  font-size: 0.95rem;
  width: 100%;

  * {
    outline: none;
  }
}

.DayPicker-wrapper {
  width: 100%;
}

.DayPicker-NavBar {
  width: 100%;
  position: relative;
}

.DayPicker-NavButton {
  right: unset;
  left: unset;

  &--prev {
    left: 1em;
  }
  &--next {
    right: 1em;
  }
}

.DayPicker-Caption > div {
  text-align: center;
}

.DayPicker-Month {
  border-spacing: 8px;
  border-collapse: initial;
}

.DayPicker-Day {
  padding: 5px;
  &--today {
    font-weight: normal !important;
  }
}

// selected day
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  border: 2px solid #89d6c6;
  border-radius: 4px;
  color: #272727;
  font-weight: 700;
  background: #fff;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #fff;
}

// selected today
.DayPicker-Day--selected.DayPicker-Day--today {
  position: relative;
  border: 2px solid #89d6c6;
  border-radius: 4px;
  color: #dce0e0;
  font-weight: 700;
  background: #fff;
}
.DayPicker-Day--selected.DayPicker-Day--today:hover {
  background-color: #fff;
}

.DayPicker-Day--today {
  color: black;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
}

.DayPicker-Day--today {
  cursor: pointer;
}

// hover
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #eef1ef;
  border-radius: 4px;
}
