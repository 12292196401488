.experience__service-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  border-top: 1px solid rgba($color: lightgray, $alpha: 0.35);

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }

  p {
    margin: 0;
    padding: 0 5px;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  &--name {
    font-size: 14px;
    font-weight: 400;
    color: #272727;
    width: 30%;
  }

  &__price {
    &--wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &--price-sale {
    font-size: 16px !important;
    font-weight: 700;
    color: #ff7063;
  }
  &--count {
    font-size: 14px;
    font-weight: 700;
    color: #272727;
    width: 20%;
  }

  &--price-underline {
    text-decoration: line-through;
    text-decoration-thickness: 3px;
  }

  &--quantity {
    color: #888;
    width: 20%;
    @media screen and (max-width: 767px) {
      text-align: right;
    }
  }

  &--group {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &--price {
    font-size: 16px !important;
    font-weight: 700;
    color: #1ab394;
  }
}
