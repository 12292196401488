.reservation-pricing-table {
  &.table-sm {
    tr {
      background-color: rgba(0, 0, 0, 0.05);
    }

    th,
    td {
      padding-left: 10px;
      padding-right: 10px;
    }

    th {
      word-break: keep-all !important;
    }

    td {
      border-top: none;
      padding-bottom: 15px;
    }
  }

  .person-count {
    width: 1.25rem;
  }

  .pricing {
    min-width: 6.25rem;
  }
}

.pricing-wrapper {
  gap: 0.5rem;

  .input-group {
    min-width: 5rem;
  }

  .input-group-append {
    width: 2.8125rem;

    span {
      width: 100%;
    }
  }

  @media (max-width: 1350px) {
    .input-group {
      max-width: 2.8125rem;
      min-width: unset;
    }
  }

  @media (max-width: 990px) {
    .input-group {
      max-width: 7.5rem;
      min-width: 5rem;
    }
  }

  @media (max-width: 675px) {
    .input-group {
      max-width: 2.8125rem;
      min-width: unset;
    }
  }
}

.reservation-duration {
  width: 25%;
  min-width: 11.875rem;

  @media (max-width: 1350px) {
    width: auto;
    min-width: unset;
  }
}
