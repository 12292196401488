@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.view-modal-title {
  max-width: 600px;
}

.view-modal-title {
  font-weight: 400 !important;
}

.view-modal-body {
  @include media-breakpoint-up(md) {
    padding: 20px 30px 30px 30px;
  }
}

.view-modal-text {
  font-size: 14px;
  font-family: inherit;
  color: #676a6c;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
