.title-address {
  font-size: 16px;

  &__row {
    display: flex;
    align-items: center;
  }

  &__icon {
    color: #1ab394;
    font-size: 17px;
  }

  &__text {
    margin: 0;
    padding-left: 4px;
  }

  .experience__row {
    justify-content: start;
    min-height: unset;
  }
}
