.event-content {
  height: 100%;

  &-flow {
    display: flex;

    &--row {
      flex-direction: row;
    }
    &--column {
      flex-direction: column;
    }
  }
}
