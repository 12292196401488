.experience {
  &__services-block {
    width: 100%;
    padding: 0 1rem;
    border: 2px solid #dbdbdb;
    border-radius: 4px;
    background-color: #fff;
  }
}
.experience__services-wrapper {
  margin-top: 1rem;
}
.experience__reservertion-options {
  .experience__block {
    width: 100%;
  }

  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.reservation__continue {
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
}
