.label {
  line-height: 16px;
  background-color: #d1dade;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 0.25em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.wrap {
  flex-wrap: wrap;
}
