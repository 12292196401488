@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

.btn-filters {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    width: 50%;
    margin: 0 25% 10px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin: 0;
  }
}
