.experience-input-block {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
  font-size: 14px;

  &__label {
    text-transform: uppercase;
    font-weight: 500;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    position: relative;
  }

  &__input {
    border: 2px solid #dedede;
    border-radius: 4px;
    padding: 5px 9px;
    width: 100%;
    min-width: 280px;

    &--error {
      border: 2px solid #f97f65;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: 0 0 4px 2px #dedede;
    }
  }
}

@media screen and (max-width: 820px) {
  .experience-input-block {
    flex-direction: column;
    align-items: flex-start;

    &__input-container {
      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }
  }
}
