.add-edit-reservation-form {
  .rbt-highlight-text {
    // dropdown highlight mark
    background: rgba(255, 244, 87, 0.5);
    font-weight: normal;
  }

  .column-title {
    font-weight: 600;
    font-size: 14px;
  }

  .acc-toggle {
    font-weight: 600;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    padding: 2px 0 2px 20px;
    cursor: pointer;

    &::before {
      content: '+';
      position: absolute;
      left: 8px;
    }

    &.active {
      color: inherit;
      background-color: #fff;
      border-color: #fff;

      &.service {
        color: #1ab394;
      }
    }

    &.opened {
      &::before {
        content: '-';
      }

      & + .collapse {
        display: block !important;
      }
    }

    &.no-icon {
      &::before {
        content: '';
      }
    }
  }

  .service {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .service-wrap {
    padding: 2px 0 2px;
  }

  .pricing {
    font-weight: 400;
  }

  .pricing-table {
    padding-left: 5px;
  }

  .collapsing,
  .collapse {
    margin-left: 15px;
  }

  .show {
    display: block !important;
    transition: none !important;
  }

  .client-select,
  .block-time-select {
    .text-danger.form-text {
      display: none;
    }
  }

  .block-time-select {
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-left: 10px;
  }
}
