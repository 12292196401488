.title {
  background-color: #ffffff !important;
}

.centerIcon {
  display: flex !important;
  align-items: center;
}

.mainCard {
  width: 98%;
  margin: auto;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.white {
  color: #ffffff !important;
}

@media only screen and (min-width: 992px) {
  .flex {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .flex {
    flex-direction: row;
  }
}
