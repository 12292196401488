.experience__drop-down {
  max-width: 332px;
  width: 100%;
  border: 2px solid #89d6c6;
  border-radius: 4px;
  min-height: 40px;
  padding-left: 7px;
  font-size: 14px;
  color: #555;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-color: #66afe9;
  }
}
