.experience__time-select {
  padding: 0.5rem;
  border: 2px solid #d7d7d7;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  overflow: auto;
  font-weight: 400;
  font-size: 16px;
  height: 315px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  grid-template-rows: repeat(auto-fit, 35px);
  grid-gap: 10px;
  color: #333;

  p {
    margin: 0;
    padding: 0.2rem;
    text-align: center;
    background-color: #fafafa;
    border: 2px solid #fafafa;

    &:hover {
      background-color: #eef1ef;
      border-color: #eef1ef;
      cursor: pointer;
    }

    &.active {
      border: 2px solid #89d6c6;
      border-radius: 4px;
      font-weight: 700;
      background-color: white;
    }
  }
}
