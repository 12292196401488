.reservation-info-item {
  font-size: 14px;
  p {
    min-width: 70px;
  }

  &--checkout {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 50%;
  }

  &__title {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 4px;
    color: #676a6c;

    &--checkout {
      width: 50%;
      text-align: end;
      padding-right: 20px;
      font-weight: 400;
    }
  }

  &__content {
    &--service {
      font-weight: 700;
    }

    &--price {
      color: #1ab394;
      font-size: 22px;
      font-weight: 700;
    }

    &--checkout {
      width: 50%;
    }
  }

  &__subcontent {
    margin-left: 16px;
    &--service {
      font-weight: 500;
    }

    &--price {
      color: #1ab394;
      font-size: 16px;
      font-weight: 700;
    }

    &--checkout {
      width: 50%;
    }
  }
}
