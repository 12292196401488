.table-sortable-container {
  .wideColumn {
    width: 50%;
  }

  .minTable {
    min-width: 700px;
  }

  .table-cells {
    color: rgb(103, 106, 108);
    font-size: 13px;
  }

  .table th,
  .table td {
    vertical-align: middle !important;
  }

  th {
    color: rgb(103, 106, 108);
    font-size: 13;
    border: none !important;
  }

  table {
    border-top: none !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .buttons-column {
    width: 10%;
  }

  .small-column {
    width: 15%;
  }

  #locationsData {
    width: 50%;
  }
}
