.loginForm {
  border-top: 1px solid #e7eaec;
  display: flex;
  flex-direction: column;
}

.button {
  width: 100%;
}

.alert {
  .h4 {
    display: flex;
    align-items: center;
    font-size: 17px;
  }
  p {
    font-size: 14px;
    line-height: 1;
  }
}
