.notifications-reset {
  font-size: 12px !important;
  padding: 1px 5px !important;
}

.notifications-email-body {
  min-height: 177px;
}

.notifications-sms {
  min-height: 106px;
}
