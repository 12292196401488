.priceId-btn {
  width: 80px;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
  background-color: #eee !important;
  font-size: 12px !important;
  color: #555 !important;
}

.copy-btn {
  background-color: #eee !important;
  font-size: 12px !important;
}

.priceId-tooltip {
  font-size: 12px;
  width: 130px;
  padding: 0;
}

.copy-tooltip {
  min-width: 130px;
  text-align: center;
}
