.experience__reservation_edit_form-wrapper {
  width: 100%;
  @media screen and (max-width: 720px) {
    margin: 0.625rem 0;
  }

  .experience__reservation_edit_form {
    border: 2px solid #dbdbdb;
    background-color: white;
    border-radius: 0.25rem;
    max-height: 7.1875rem;
    min-height: 7.1875rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      padding: 0;
      margin: 0;
    }

    &-inner-wrapper {
      padding: 0.1875rem 0.3125rem 0.1875rem 0.75rem;
    }

    .experience__reservation_edit_form-actions {
      display: flex;
      margin: 0.625rem 0 0 auto;

      img {
        margin-right: 0.3125rem;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .experience__reservation_edit_form-item {
      height: fit-content;
      display: flex;
      align-items: flex-start;
      &--editing,
      &--disabled {
        background-color: #1ab39430;
        .experience__reservation_edit_form-time-info {
          font-weight: 500;
        }
      }
    }
    .experience__reservation_edit_form-service {
      font-weight: 700;
      font-size: 1rem;
    }

    .experience__reservation_edit_form-time-info {
      font-size: 0.875rem;
      font-weight: 400;
      max-width: 20rem;
    }

    @media screen and (max-width: 720px) {
      max-width: 20.625rem;
    }
  }
}
.experience__edit-block {
  margin-left: auto;
}
