.input {
  max-width: 180px;
  min-width: 100px;
}

.select {
  min-width: 300px;
}
.space-left {
  margin-left: 4px;
}
.dropdown-menu {
  max-height: 320px;
  overflow-y: auto;
}
.dropdown-input {
  min-width: 338px !important;
  &:disabled {
    background-color: white !important;
  }
}

#table-dropdown-input {
  min-width: 338px;
}

@media only screen and (max-width: 760px) {
  .employee-filter-inputs {
    flex-direction: column;
  }
  .dropdown-input {
    min-width: unset !important;
  }
  .dropdown-input-wrapper {
    width: 100%;
    max-width: unset !important;
    min-width: unset !important;
  }

  #table-dropdown-input {
    width: 100%;
    min-width: unset !important;
  }
}
