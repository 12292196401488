// Row
.experience {
  &__row {
    min-height: 65px;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 820px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
