.landing__footer {
  padding: 1rem;
  &__terms-privacy-wrapper > a {
    color: #2e4050;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-decoration: none;
  }
}

.footer__wrapper {
  margin-top: auto;
}
